<script setup lang="ts">

const props = defineProps<{
  headline: string;
  headlineType: string;
  class?: string;
}>();

const hClass = props.class;

</script>
<template>
  <template v-if="headline">
    <template v-if="headlineType == 'h1'">
      <h1 :class="'section-title h2 ' + hClass" v-html="headline"></h1>
    </template>
    <template v-else-if="headlineType == 'h2'">
      <h2 :class="'section-title h2 ' + hClass" v-html="headline"></h2>
    </template>
    <template v-else-if="headlineType == 'h3'">
      <h3 :class="'section-title h2 ' + hClass" v-html="headline"></h3>
    </template>
    <template v-else-if="headlineType == 'h4'">
      <h4 :class="'section-title h2 ' + hClass" v-html="headline"></h4>
    </template>
    <template v-else-if="headlineType == 'h5'">
      <h5 :class="'section-title h2 ' + hClass" v-html="headline"></h5>
    </template>
    <template v-else-if="headlineType == 'h6'">
      <h6 :class="'section-title h2 ' + hClass" v-html="headline"></h6>
    </template>
  </template>
</template>